import { HttpInstance, HttpClientResponse, CacheOptions } from '@grupa-pracuj/http-client-relax/axios';

export type AgreementProps = {
  codes: string[];
};

export const accountRegistrationAgreementsCodes = [
  'gp-commercial-information-by-email',
  'available-for-employer',
  'customers-commercial-information-by-email',
];

export type AgreementData = {
  Id: number;
  Code: string;
  Text: string;
  ExtendedText: string;
  Links?: { Text: string; Href: string }[];
};

const getAgreements =
  (http: HttpInstance) =>
  ({ codes }: AgreementProps): Promise<HttpClientResponse<AgreementData[]>> => {
    const queryString = `?${codes.map((code) => `codes=${code}`).join('&')}`;

    return http.get(`/api/private/agreements/content${queryString}`, { cache: CacheOptions.Enabled });
  };

export default getAgreements;

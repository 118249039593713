import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax/axios';

export type SignupProviderUserProps = {
  agreements: {
    code: string;
    value: boolean;
  }[];
  provider: 'facebook' | 'google';
  emailOriginId?: number | string;
};

export type SignupProviderResponseData = {
  emailOriginId: number;
  registrationSourceId: number;
  userId: number;
};

const postSignupProviderUser =
  (http: HttpInstance) =>
  ({
    agreements,
    provider,
    emailOriginId,
  }: SignupProviderUserProps): Promise<HttpClientResponse<SignupProviderResponseData>> =>
    http.post(`/api/public/signup-${provider}-user`, JSON.stringify({ agreements, emailOriginId }));

export default postSignupProviderUser;

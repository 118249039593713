import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax/axios';

type Agreements = {
  code: string;
  value: boolean;
};

type SignUpProperties = {
  password: string;
  email: string;
  agreements: Agreements[];
  returnUrl: string | null;
  emailOriginId: number | string;
};

export type SignUpResponseData = {
  emailOriginId: number;
  registrationSourceId: number;
  userId: number;
};

const postSignUp =
  (http: HttpInstance) =>
  (requestProps: SignUpProperties): Promise<HttpClientResponse<SignUpResponseData>> =>
    /**
     * @TODO: Temporary timeout value, should be removed after BE will be ready
     */
    http.post('/api/public/users/sign-up', JSON.stringify(requestProps), { timeout: 7000 });

export default postSignUp;

import { useState, useCallback } from 'react';

import { HandleRequest, RequestHandler, UseHttpRequestReturn } from './http-hook.models';
import { HttpClientResponse } from './http-client.models';
import { HttpInstance } from './http-client';

export default function useHttpRequest<ResponseType, RequestProps>(
  http: HttpInstance,
  requestHandler: RequestHandler<ResponseType, RequestProps>,
): UseHttpRequestReturn<ResponseType, RequestProps> {
  const [isLoading, setIsLoading] = useState(false);
  const [httpClientResponse, setHttpClientResponse] = useState<HttpClientResponse<ResponseType>>({
    headers: null,
    status: null,
    data: null,
    error: null,
    exception: null,
  });

  const handleRequest: HandleRequest<ResponseType, RequestProps> = useCallback(
    async (props) => {
      setIsLoading(true);

      const handle = requestHandler(http);

      const response = await handle(props);

      setHttpClientResponse(response);
      setIsLoading(false);

      return response;
    },
    [http, requestHandler],
  );

  return { ...httpClientResponse, isLoading, handleRequest };
}

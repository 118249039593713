import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax/axios';

export enum Process {
  Login = 'login',
  Register = 'register',
  Confirm = 'confirm',
  ConfirmAndSetPassword = 'confirmandsetpassword',
}

export type AuthProcessData = {
  process: Process;
};

export type AuthProcessProps = { email: string };

const getAuthProcess =
  (http: HttpInstance) =>
  ({ email }: AuthProcessProps): Promise<HttpClientResponse<AuthProcessData>> =>
    http.get(`/api/public/users/auth/process?email=${encodeURIComponent(email)}`);

export default getAuthProcess;

import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax/axios';

type ConfirmAccountAndSetPasswordProperties = {
  userId: number;
  activationToken: string;
  loginToken: string;
  password: string;
  passwordToken: string;
};

const putConfirmAccountAndSetPassword =
  (http: HttpInstance) =>
  (requestProps: ConfirmAccountAndSetPasswordProperties): Promise<HttpClientResponse<null>> =>
    http.put('/api/public/users/confirm', JSON.stringify(requestProps));

export default putConfirmAccountAndSetPassword;

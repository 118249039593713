import { HttpInstance, HttpClientResponse } from '@grupa-pracuj/http-client-relax/axios';

export type DoubleCSRFData = {
  message: string;
  csrfToken: string;
  csrfTokenHash: string;
  csrfCookieName: string;
  csrfHeaderName: string;
};

export const defaultDoubleCSRFState: DoubleCSRFData = {
  message: '',
  csrfToken: '',
  csrfTokenHash: '',
  csrfCookieName: 'x-auth-csrf-token',
  csrfHeaderName: 'x-auth-csrf-hash',
};

const getDoubleCSRF = (http: HttpInstance) => (): Promise<HttpClientResponse<DoubleCSRFData>> =>
  http.get('/api/public/double-csrf');

export default getDoubleCSRF;
